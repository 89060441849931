<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">LEAVES COUNT</span>
          <v-spacer></v-spacer>
          <v-flex xs6 sm6 md6 text-right>
            <v-btn
              color="#005f32"
              dark
              outlined
              @click="dialogAdd = true"
              rounded
              >Add New</v-btn
            >
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start pa-5 v-if="leaveArray.length > 0">
          <v-flex xs12>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead style="background-color: #005f32">
                  <tr>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Leaves Count
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Leave Category
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Status
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in leaveArray"
                    :key="i"
                  >
                    <td>
                      <span class="" style="font-family: kumbhMedium">{{
                        item.count
                      }}</span>
                    </td>
                    <td>
                      <span
                        v-if="item.leaveCategory"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.leaveCategory }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.leaveStatus"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.leaveStatus }}</span
                      >
                    </td>
                    <td>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-2>
                          <v-icon
                            @click="(dialogEdit = true), (editItem = item)"
                            title="View"
                            color="#005f32"
                            >mdi-pencil</v-icon
                          >
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Data found!</span>
          </v-flex>
        </v-layout>
        <v-dialog persistent v-model="dialogAdd" max-width="50%">
          <v-card tile>
            <v-card-title>
              <span class="itemHeading">Leaves</span>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12 sm12 pt-5 px-5 text-left>
                  <span class="itemText2"
                    >Please Enter the Total Leaves of Employees *</span
                  >
                  <v-text-field outlined dense v-model="count"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 pt-5 px-5 text-left>
                  <span class="itemText2"
                    >Please Enter the Leave Category *</span
                  >
                  <v-combobox
                    v-model="leaveCategory"
                    :items="leaveCategories"
                    :search-input.sync="search"
                    hide-selected
                    outlined
                    dense
                    item-text="title"
                    item-value="value"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ search }}</strong
                            >". Press <kbd>enter</kbd> to create a new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                  <!-- <v-select
                    outlined
                    dense
                    v-model="leaveCategory"
                    :items="leaveCategories"
                    item-text="title"
                    item-value="value"
                  ></v-select> -->
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogAdd = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="add()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogEdit" max-width="50%">
          <v-card tile>
            <v-card-title>
              <span class="itemHeading">Leaves</span>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12 sm12 pt-5 px-5 text-left>
                  <span class="itemText2"
                    >Please Enter the Total Leaves of Employees *</span
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="editItem.count"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 pt-5 px-5 text-left>
                  <span class="itemText2"
                    >Please Enter the Leave Category *</span
                  >
                  <v-text-field
                    outlined
                    dense
                    readonly
                    v-model="editItem.leaveCategory"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogEdit = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="edit(editItem)">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
export default {
  data: (vm) => ({
    showsnackbar: false,
    ServerError: false,
    msg: null,
    search:"",
    pages: 0,
    dialog: false,
    pageCount: 1,
    appLoading: false,
    currentPage: 1,
    g: false,
    totalData: 0,
    addcat: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    dateFormatted: vm.formatDateMonth(new Date().toISOString().substr(0, 7)),
    leaveArray: [],
    data: [],
    count: null,
    assetId: null,
    editItem: "",
    dialogEdit: false,
    dialogAdd: false,
    leavesCount: [],
    year: "",
    leaveCategory: "",
    leaveCategories: [
      {
        title: "Duty",
        value: "Duty",
      },
      {
        title: "Paid",
        value: "Paid",
      },
      {
        title: "Compensatory",
        value: "Compensatory",
      },
      {
        title: "Unpaid",
        value: "Unpaid",
      },
      {
        title: "Sick",
        value: "Sick",
      },
      {
        title: "Maternity",
        value: "Maternity",
      },
      {
        title: "Paternity",
        value: "Paternity",
      },
    ],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/organisation/leave/details/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          page: this.currentPage,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.leaveArray = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      //   var data1 = {};
      //   this.leavesCount.push({ year: this.year, count: this.count });
      var leaveDetails = [];
      leaveDetails.push({
        leaveCategory: this.leaveCategory,
        count: this.count,
      });
      //   data1["leaveDetails"] = leaveDetails;
      axios({
        method: "POST",
        url: "/organisation/leave/details/add",
        data: {
          leaveDetails: leaveDetails,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.visible = false;
          this.status = response.data.status;
          this.msg = response.data.msg;
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialogAdd = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.visible = false;
          if (err.response.data.status === false) {
            this.msg = "Please Try Again";
          }
        });
    },
    edit(item) {
      var leaveDetails = [];
      leaveDetails.push({
        leaveCategory: this.editItem.leaveCategory,
        count: this.editItem.count,
      });
      axios({
        method: "POST",
        url: "/organisation/leave/details/edit/" + item._id,
        data: {
          leaveDetails: item,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.visible = false;
          this.status = response.data.status;
          this.msg = response.data.msg;
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialogEdit = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.visible = false;
          if (err.response.data.status === false) {
            this.msg = "Please Try Again";
          }
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    formatDateMonth(date) {
      if (!date) return null;
      const [year, month] = date.split("-");
      return `${month}-${year}`;
    },
  },
};
</script>
  <style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>
    